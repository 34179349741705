import forEach from 'lodash/forEach';
import has from 'lodash/has';
import size from 'lodash/size';
import {
  INITIAL_STATUS,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { toastError } from '@/utils';

const {
  getDevice,
  postDevice,
  putDevice,
  deleteDevice,
} = backOffice.devices;

export const scopes = {
  form: 'form',
  deleteForm: 'deleteForm',
};

const devices = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  state: {
    data: null,
    [scopes.form]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [scopes.deleteForm]);
    },
    clearErrors(state, scopesToClear = []) {
      if (size(scopesToClear)) {
        forEach(scopesToClear, scopeToClear => {
          if (has(scopes, scopeToClear)) {
            state[scopeToClear].error = null;
            state[scopeToClear].STATUS = { ...INITIAL_STATUS };
          }
        });
      } else {
        state.error = null;
        state.STATUS = { ...INITIAL_STATUS };
      }
    },
  },
  actions: {
    async getDevice({ commit }, deviceUuid) {
      await runAsyncFlow(commit, {
        request: getDevice,
        params: [deviceUuid],
      });
    },
    async postDevice({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postDevice,
        params: [data],
        scope: scopes.form,
      });
    },
    async putDevice({ commit }, { deviceUuid, data }) {
      await runAsyncFlow(commit, {
        request: putDevice,
        params: [deviceUuid, data],
        scope: scopes.form,
      });
    },
    async deleteDevice({ commit }, deviceUuid) {
      await runAsyncFlow(commit, {
        request: deleteDevice,
        params: [deviceUuid],
        scope: scopes.deleteForm,
      });
    },
  },
})));

export default devices;
