import each from 'lodash/each';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError as globalSetError } from '@/store/setError';

const {
  getSim,
  postSim,
  putSim,
  deleteSim,
} = backOffice.sims;

export const SIM_SCOPES = {
  sim: 'sim',
  newSim: 'newSim',
  deleteSim: 'deleteSim',
};

const sims = createCustomStore(({ runAsyncFlow }) => withScopes(SIM_SCOPES, ({
  state: {
    [SIM_SCOPES.sim]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === SIM_SCOPES.deleteSim) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    clearErrors(state) {
      each(SIM_SCOPES, scope => {
        state[scope].error = null;
        state[scope].STATUS = { ...INITIAL_STATUS };
      });
    },
  },
  actions: {
    async getSim({ commit }, simUuid) {
      await runAsyncFlow(commit, {
        request: getSim,
        params: [simUuid],
        scope: SIM_SCOPES.sim,
      });
    },
    async postSim({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postSim,
        params: [data],
        scope: SIM_SCOPES.newSim,
      });
    },
    async putSim({ commit }, { simUuid, data }) {
      await runAsyncFlow(commit, {
        request: putSim,
        params: [simUuid, data],
        scope: SIM_SCOPES.newSim,
      });
    },
    async deleteSim({ commit }, simUuid) {
      await runAsyncFlow(commit, {
        request: deleteSim,
        params: [simUuid],
        scope: SIM_SCOPES.deleteSim,
      });
    },
  },
})));

export default sims;
